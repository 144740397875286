import { useUrls } from '@/composables/useUrls';
import { useApiAreas } from '~/composables/useApiAreas';
import { useRoutes } from '~/composables/useRoutes.js';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { getAreaByName } = useApiAreas();
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;
    const urlPath = to?.path.toLowerCase();

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // VALIDATE AREA WITH CALL TO SPAPI
    const locationObj = await getAreaByName(propertyTypeObj?.key, urlParams.country, urlParams.area);

    if (locationObj?.id) {
      // AREA SEEMS TO BE A VALID ONE.
      // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
      const areaPageUrlForCurrentLang = useUrl.getAreaPageUrl(
        {
          propertyTypeSlug: propertyTypeObj.slug,
          urlFriendlyContinent: locationObj.urlFriendlyContinent,
          urlFriendlyCountry: locationObj.urlFriendlyCountry,
          urlFriendlyArea: locationObj.urlFriendlyName,
        },
        urlQuery,
      );

      const areaPagePathForCurrentLang = new URL(areaPageUrlForCurrentLang).pathname.toLowerCase();

      if (areaPagePathForCurrentLang !== urlPath) {
        // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
        // REDIRECT USER TO THE APPROPRIATE URL FORMAT
        navigateTo(areaPagePathForCurrentLang, {
          redirectCode: 301,
          external: false,
        });
      }
    } else {
      // AREA IS NOT FOUND, THROW 404 TO CHECK THE REDIRECTION API
      const errorDescription = `### No such Area '${urlParams.area}' in Country '${urlParams.country}'`;
      console.error(errorDescription);

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        data: useRouteManager.enrichedErrorResponse(errorDescription, urlPath),
      });
    }
  }
});
